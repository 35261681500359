<template>
  <FloatingMenu>
    <template #activator="{ show }">
      <button
        class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center whitespace-nowrap bg-yellow-200 text-yellow-800 fill-yellow-800"
        :disabled="isReviewReadOnly"
        @click="show"
      >
        Citation
        <ArrowDownIcon
          v-if="!isReviewReadOnly && arrow"
          class="w-3 h-3 fill-current"
        />
      </button>
    </template>
    <template #content="{ hide }">
      <div class="space-y-2">
        <RisDropZone v-model="citationFiles" />
        <button
          :disabled="citationFiles.length === 0"
          :class="{
            '!bg-black/5 !text-black/40': citationFiles.length === 0,
          }"
          class="w-full flex text-sm gap-1 items-center justify-center bg-blue-800 text-white rounded-lg px-4 py-3 font-medium text-center"
          @click="importCitation(citationFile, hide)"
        >
          <ImportIcon class="w-5" /> Import
        </button>
      </div>
    </template>
  </FloatingMenu>
</template>
<script setup lang="ts">
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import ArrowDownIcon from '@app/components/Icons/ArrowDownIcon.vue'
import ImportIcon from '@app/components/Icons/ImportIcon.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { injectStrict } from '@app/utils/injectStrict'
import RisDropZone from '@app/views/Review/Plan/Search/Components/RisDropZone.vue'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    reviewItem: ReviewItem
    isReviewReadOnly?: boolean
    arrow?: boolean
  }>(),
  {
    isReviewReadOnly: false,
    arrow: true,
  },
)
const builtInImportSources = useBuiltInImportSources()
const loading = useLoading()
const snackbar = useSnackbar()
const review = injectStrict(ReviewKey)
const citationFiles = ref<{ isValid: boolean; file: File }[]>([])
const citationFile = computed(() => citationFiles.value[0])
const citationSearch = builtInImportSources.findById(
  BuiltInImportSourceId.CITATION_SEARCH,
)!
async function importCitation(
  file: { isValid: boolean; file: File },
  cb: () => void,
) {
  if (!file?.isValid) return
  loading.start()
  try {
    const dataSource = review.entity.value.plan?.importPlan.importSources?.find(
      (source) => source.id === BuiltInImportSourceId.CITATION_SEARCH,
    )
    if (!dataSource) {
      await review.addCustomImportSourceToPlan({
        id: citationSearch.id,
        name: citationSearch.name,
        description: '',
        url: citationSearch.url ?? '',
        type: citationSearch.type,
      })
    }

    await review.importCitationSearch({
      citationFiles: [file.file],
      parentStudyId: props.reviewItem.id,
    })
    cb()
    snackbar.success('Citation imported successfully')
    citationFiles.value = []
  } catch (_e) {
    snackbar.error('Failed to import citation')
  } finally {
    loading.stop()
  }
}
</script>
