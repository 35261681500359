<template>
  <div class="flex items-center gap-2">
    <template v-if="isPlanEditable">
      <Modal
        v-if="
          [BuiltInImportSourceId.HAND_SEARCH].includes(
            importSource.id as BuiltInImportSourceId,
          )
        "
      >
        <template #activator="{ show }">
          <button
            class="flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
            @click="() => (isPlanEditable ? show() : null)"
          >
            <SourceIcon :source="importSource" :icon-url="iconUrl" />
            {{ importSource.name }}
            <ImportIcon class="w-5 text-blue-800" />
          </button>
        </template>
        <template #content="{ hide }">
          <HandSearch @hide="hide" />
        </template>
      </Modal>

      <Modal
        v-else-if="
          [BuiltInImportSourceId.FIELD_SAFETY_NOTICES].includes(
            importSource.id as BuiltInImportSourceId,
          )
        "
      >
        <template #activator="{ show }">
          <button
            class="flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
            @click="() => (isPlanEditable ? show() : null)"
          >
            <SourceIcon :source="importSource" :icon-url="iconUrl" />
            {{ importSource.name }}
            <ArrowsPointingOutIcon class="w-5 text-blue-800" />
          </button>
        </template>
        <template #content="{ hide }">
          <FsnSearch @hide="hide" />
        </template>
      </Modal>
      <template v-else-if="isBuiltInImportSource(importSource.id)">
        <Modal
          v-if="hasMetadataExtraction(importSource.id) && !isExtensionInstalled"
          dismissible
        >
          <template #activator="{ show }">
            <button
              class="truncate flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
              @click="show"
            >
              <SourceIcon :source="importSource" :icon-url="iconUrl" />
              {{ importSource.name }}
              <ExternalLinkIcon class="w-5 text-blue-800" />
            </button>
          </template>
          <template #content="{ hide }">
            <div
              class="w-[548px] p-6 bg-slate-100 rounded-lg shadow-lg space-y-6"
            >
              <div class="flex justify-between items-center">
                <div class="flex gap-2 items-center">
                  <EvidenceLogo class="w-5 h-5" />
                  <div class="text-primary font-semibold text-base">
                    Install the Chrome extension
                  </div>
                </div>
                <button class="" @click="hide">
                  <XMarkIcon class="w-6 text-slate-400 hover:text-slate-600" />
                </button>
              </div>
              <div class="text-sm">
                You must install the Chrome extension before you proceed.
              </div>
              <div class="flex justify-center gap-6">
                <a
                  href="https://chromewebstore.google.com/detail/evidence-extension/bifaoaidegbcmjliabaeabnniphbaodi"
                  target="_blank"
                  rel="noopener"
                  class="text-blue-500 border border-slate-400 rounded-md hover:text-blue-800 text-sm font-medium leading-[-0.13px] flex items-center gap-2"
                >
                  <img
                    src="/download-extension.png"
                    alt="Install Evidence Extension"
                  />
                </a>
              </div>
            </div>
          </template>
        </Modal>
        <a
          v-else
          :href="importSource.url"
          rel="noopener"
          class="truncate flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
          target="_blank"
        >
          <SourceIcon :source="importSource" :icon-url="iconUrl" />
          {{ importSource.name }}
          <ExternalLinkIcon class="w-5 text-blue-800" />
        </a>
      </template>
      <Modal v-else>
        <template #activator="{ show }">
          <button
            class="flex flex-row gap-2 items-center rounded-md font-medium hover:underline"
            @click="() => (isPlanEditable ? show() : null)"
          >
            <SourceIcon :source="importSource" :icon-url="iconUrl" />
            {{ importSource.name }}
            <ImportIcon class="w-5 text-blue-800" />
          </button>
        </template>
        <template #content="{ hide }">
          <CustomSource
            :import-source="importSource"
            :readonly="isPlanEditable"
            @hide="hide"
          />
        </template>
      </Modal>
    </template>
    <template v-else>
      <SourceIcon :source="importSource" :icon-url="iconUrl" />
      {{ importSource.name }}
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { ImportSource } from '@core/domain/models/import-source.model'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import Modal from '@app/components/Global/Modal/Modal.vue'
import HandSearch from './HandSearch.vue'
import ImportIcon from '@app/components/Icons/ImportIcon.vue'
import ExternalLinkIcon from '@app/components/Icons/ExternalLinkIcon.vue'
import FsnSearch from '../SourcesList/FsnSearch/FsnSearch.vue'
import { ArrowsPointingOutIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import SourceIcon from './SourceIcon.vue'
import CustomSource from './CustomSource.vue'
import EvidenceLogo from '@app/components/Icons/EvidenceLogo.vue'
import useWebExtension from '@app/composables/useWebExtension'

const review = injectStrict(ReviewKey)
const isPlanEditable = computed(() => !review.isPlanReadonly.value)
const { isExtensionInstalled } = useWebExtension()
defineProps<{
  importSource: ImportSource
  iconUrl?: string
}>()

const builtInImportSources = useBuiltInImportSources()
const isBuiltInImportSource = (importSourceId: string) => {
  return !!builtInImportSources.findById(importSourceId)
}

const hasMetadataExtraction = (importSourceId: string) => {
  const source = builtInImportSources.findById(importSourceId)
  return source?.isEvidenceMetadataExtractionTechnology ?? false
}
</script>
