<template>
  <div class="h-full">
    <div class="space-y-4">
      <div class="space-y-2">
        <p class="text-sm text-black/90 font-medium pointer-events-none">
          Enter the device trade name exactly as it appears on the certificate,
          including any ® or ™ or © symbols.<br />Do not include the
          manufacturer name if it is not part of the device trade name.
        </p>
        <div>
          <div
            v-if="preset.deviceName"
            class="flex items-center w-fit text-sm gap-2 px-3 py-2 rounded-full text-primary bg-gray-200 leading-none"
          >
            {{ preset.deviceName }}
            <button @click="clearDeviceName()">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <TextInput
            v-else
            ref="deviceNameInput"
            v-model="tempDeviceName"
            placeholder="Device trade name"
            type="text"
            class="w-96"
            :rules="[(v) => !!v || 'Device name is required']"
            @enter="(v) => addDeviceName(v)"
          >
            <template #suffix>
              <div
                class="inline-flex items-center rounded border shadow-md border-gray-200 px-2.5 font-sans text-xs text-gray-400 py-1 gap-1"
              >
                <ArrowTurnDownLeftIcon class="w-3.5" />
              </div>
            </template>
          </TextInput>
        </div>
      </div>
      <div v-if="preset.deviceName" class="space-y-2">
        <div class="flex gap-8">
          <p class="text-sm text-black/90 font-medium">
            Devices are rebranded for marketing or regulatory reasons.<br />
            Did the same devices previously have a different name?
          </p>
          <div>
            <button
              class="bg-slate-200 px-2 py-1 rounded-l-md border border-slate-300"
              :class="{
                '!bg-primary text-white !border-primary':
                  deviceHasAlternativeNames === HasAlternativeNames.No,
              }"
              @click="handleDeviceAlternativeNames(HasAlternativeNames.No)"
            >
              No
            </button>
            <button
              class="bg-slate-200 px-2 py-1 rounded-r-md border border-l-0 border-slate-300"
              :class="{
                '!bg-primary text-white':
                  deviceHasAlternativeNames === HasAlternativeNames.Yes,
              }"
              @click="handleDeviceAlternativeNames(HasAlternativeNames.Yes)"
            >
              Yes
            </button>
          </div>
        </div>

        <div v-show="deviceHasAlternativeNames === HasAlternativeNames.Yes">
          <div class="flex flex-wrap items-center gap-2">
            <div
              v-for="alternativeName in preset.alternativeDeviceNames"
              :key="'alternative-device-name-' + alternativeName"
              class="flex items-center text-sm gap-2 px-3 py-2 rounded-full text-primary bg-gray-200 leading-none"
            >
              {{ alternativeName }}
              <button @click="removeAlternativeDeviceName(alternativeName)">
                <XCircleIcon class="w-5 h-5" />
              </button>
            </div>
            <TextInput
              ref="alternativeDeviceNameInput"
              v-model="newAlternativeDeviceName"
              placeholder="Previous device name"
              class="w-96"
              @enter="(v) => addAlternativeDeviceName(v)"
            >
              <template #suffix>
                <div
                  class="inline-flex items-center rounded border shadow-md border-gray-200 px-2.5 font-sans text-xs text-gray-400 py-1 gap-1"
                >
                  <ArrowTurnDownLeftIcon class="w-3.5" />
                </div>
              </template>
            </TextInput>
          </div>
        </div>
      </div>
      <div v-if="loadingMessage" class="py-2 px-1">
        {{ loadingMessage + animatedDots }}
      </div>
      <div
        v-if="
          openAlexWorkCount > 500 &&
          loadingMessage === '' &&
          ((deviceHasAlternativeNames === HasAlternativeNames.Yes &&
            preset.alternativeDeviceNames.length > 0) ||
            deviceHasAlternativeNames === HasAlternativeNames.No)
        "
        class="space-y-4"
      >
        <div class="space-y-2">
          <p class="text-sm text-black/90 font-medium">
            Name of the legal manufacturer.<br />
          </p>
          <div
            v-if="preset.manufacturerName"
            class="flex items-center w-min text-sm gap-2 px-3 py-2 rounded-full text-primary bg-gray-200 leading-none"
          >
            {{ preset.manufacturerName }}
            <button @click="clearManufacturerName()">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <TextInput
            v-else
            v-model="tempManufacturerName"
            class="w-96"
            placeholder="Legal manufacturer name"
            type="text"
            @enter="(v) => addManufacturerName(v)"
          >
            <template #suffix>
              <div
                class="inline-flex items-center rounded border shadow-md border-gray-200 px-2.5 font-sans text-xs text-gray-400 py-1 gap-1"
              >
                <ArrowTurnDownLeftIcon class="w-3.5" />
              </div>
            </template>
          </TextInput>
        </div>
        <template v-if="preset.manufacturerName">
          <div class="flex gap-8">
            <p class="text-sm text-black/90 font-medium pointer-events-none">
              Companies evolve, rebrand, or are acquired.<br />
              Did the manufacturer previously have a different name?
            </p>
            <div>
              <button
                class="bg-slate-200 px-2 py-1 rounded-l-md border border-slate-300"
                :class="{
                  '!bg-primary text-white !border-primary':
                    manufacturerHasAlternativeNames === HasAlternativeNames.No,
                }"
                @click="
                  handleManufacturerAlternativeNames(HasAlternativeNames.No)
                "
              >
                No
              </button>
              <button
                class="bg-slate-200 px-2 py-1 rounded-r-md border border-l-0 border-slate-300"
                :class="{
                  '!bg-primary text-white':
                    manufacturerHasAlternativeNames === HasAlternativeNames.Yes,
                }"
                @click="
                  handleManufacturerAlternativeNames(HasAlternativeNames.Yes)
                "
              >
                Yes
              </button>
            </div>
          </div>
        </template>
        <div
          v-show="
            manufacturerHasAlternativeNames === HasAlternativeNames.Yes &&
            preset.manufacturerName
          "
          class="flex-col space-y-2"
        >
          <div class="flex flex-wrap items-center gap-2">
            <div
              v-for="alternativeManufacturerName in preset.alternativeManufacturerNames"
              :key="
                'alternative-manufacture-name-' + alternativeManufacturerName
              "
              class="flex items-center text-sm gap-2 px-3 py-2 min-h-10 rounded-full text-primary bg-gray-200 leading-none"
            >
              {{ alternativeManufacturerName }}
              <button
                @click="
                  removeAlternativeManufacturerName(alternativeManufacturerName)
                "
              >
                <XCircleIcon class="w-5 h-5" />
              </button>
            </div>
            <TextInput
              ref="alternativeManufacturerNameInput"
              v-model="newAlternativeManufacturerName"
              class="w-96"
              placeholder="Previous manufacturer name"
              @enter="(v) => addAlternativeManufacturerNames(v)"
            >
              <template #suffix>
                <div
                  class="inline-flex items-center rounded border shadow-md border-gray-200 px-2.5 font-sans text-xs text-gray-400 py-1 gap-1"
                >
                  <ArrowTurnDownLeftIcon class="w-3.5" />
                </div>
              </template>
            </TextInput>
          </div>
        </div>
      </div>
    </div>
    <SimilarDeviceSpecificSearchFsnCountrySelection
      v-if="
        preset.preset === ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH &&
        isPmsDeviceAndManufacturerNamesValid() &&
        !isGatheringBackgroundInformation
      "
      class="mt-4"
    />
  </div>
</template>
<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { ref, computed, useTemplateRef, nextTick, onMounted } from 'vue'
import { getOpenAlexWorkCount } from '@app/utils/openAlex'
import { injectStrict } from '@app/utils/injectStrict'
import {
  CreateReviewKey,
  HasAlternativeNames,
} from '../../../useCreateProjectReview'
import { ArrowTurnDownLeftIcon } from '@heroicons/vue/24/outline'
import { generateMessageWithDelay } from '@app/utils/generateTextWithDelay'
import SimilarDeviceSpecificSearchFsnCountrySelection from '../SimilarDeviceSpecificSearchPreset/SimilarDeviceSpecificSearchFsnCountrySelection.vue'
import { ReviewPreset } from '@core/domain/types/review-preset.type'

const {
  preset,
  openAlexWorkCount,
  deviceHasAlternativeNames,
  manufacturerHasAlternativeNames,
  isGatheringBackgroundInformation,

  isPmsDeviceAndManufacturerNamesValid,
} = injectStrict(CreateReviewKey)

const tempDeviceName = ref('')
const tempManufacturerName = ref('')
const newAlternativeDeviceName = ref('')
const newAlternativeManufacturerName = ref('')

const deviceNamesSearchTerm = computed(() =>
  combineDeviceName(
    preset.value.deviceName,
    preset.value.alternativeDeviceNames,
  ),
)

const alternativeDeviceNameInput = useTemplateRef('alternativeDeviceNameInput')
const alternativeManufacturerNameInput = useTemplateRef(
  'alternativeManufacturerNameInput',
)
const deviceNameInput = useTemplateRef('deviceNameInput')

onMounted(() => {
  nextTick(() => deviceNameInput.value?.focus())
})

const sanitizeInput = (input: string) => {
  const trimmedInput = input.replace(/(^[^a-zA-Z0-9]+)|([^a-zA-Z0-9]+$)/g, '')

  return trimmedInput.replace(/[^a-zA-Z0-9]+/g, ' ')
}

const formatDeviceName = (name: string) => {
  const sanitizedDeviceName = sanitizeInput(name)
  return sanitizedDeviceName.trim().replace(/\s+/g, ' AND ')
}

const formatAlternativeNames = (names: string) => {
  return names
    .trim()
    .split(',')
    .map((name) => sanitizeInput(name).replace(/\s+/g, ' AND '))
    .filter((name) => name.length > 0)
    .map((name) => `(${name})`)
    .join(' OR ')
}

const combineDeviceName = (device: string, alternatives: string[]) => {
  const formattedDeviceName = formatDeviceName(device)
  const formattedAlternativeNames = alternatives
    .map(formatAlternativeNames)
    .join(' OR ')

  return formattedAlternativeNames
    ? `(${formattedDeviceName}) OR ${formattedAlternativeNames}`
    : `${formattedDeviceName}`
}
const loadingMessage = ref('')

async function appendMessage(
  message: string,
  delayInMs: number = 1000,
): Promise<void> {
  const generator = generateMessageWithDelay(message)
  for await (const word of generator) {
    loadingMessage.value += `${word} `
  }
  await new Promise<void>((resolve) => setTimeout(resolve, delayInMs))
}

const animatedDots = ref('')

setInterval(() => {
  animatedDots.value =
    animatedDots.value.length < 3 ? animatedDots.value + '.' : '.'
}, 200)

async function updateOpenAlexCount() {
  isGatheringBackgroundInformation.value = true
  try {
    const [, count] = await Promise.all([
      appendMessage('Gathering background information'),
      getOpenAlexWorkCount(deviceNamesSearchTerm.value),
    ])
    openAlexWorkCount.value = count
  } catch (e) {
    const error = e as HttpException

    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loadingMessage.value = ''
    isGatheringBackgroundInformation.value = false
  }
}

function addDeviceName(deviceName: string) {
  if (deviceName) {
    preset.value.deviceName = deviceName

    tempDeviceName.value = ''
  }
}

function clearDeviceName() {
  preset.value.deviceName = ''
  removeAllAlternativeDeviceNames()
  openAlexWorkCount.value = 0
  deviceHasAlternativeNames.value = HasAlternativeNames.Na
  clearManufacturerName()
}

function addManufacturerName(manufacturerName: string) {
  if (manufacturerName) {
    preset.value.manufacturerName = manufacturerName
    tempManufacturerName.value = ''
  }
}

function clearManufacturerName() {
  preset.value.manufacturerName = ''
  removeAllAlternativeManufacturerNames()
  manufacturerHasAlternativeNames.value = HasAlternativeNames.Na
}

function addAlternativeDeviceName(alternativeDeviceName: string) {
  if (alternativeDeviceName) {
    preset.value.alternativeDeviceNames.push(alternativeDeviceName)
    newAlternativeDeviceName.value = ''
  }
  updateOpenAlexCount()
}

function addAlternativeManufacturerNames(alternative: string) {
  if (alternative) {
    preset.value.alternativeManufacturerNames.push(alternative)
    newAlternativeManufacturerName.value = ''
  }
}

function removeAlternativeDeviceName(device: string) {
  const index = preset.value.alternativeDeviceNames.indexOf(device)

  if (index !== -1) {
    preset.value.alternativeDeviceNames.splice(index, 1)
  }
  updateOpenAlexCount()
}

function removeAllAlternativeDeviceNames() {
  preset.value.alternativeDeviceNames = []
}

function removeAllAlternativeManufacturerNames() {
  preset.value.alternativeManufacturerNames = []
}

function removeAlternativeManufacturerName(
  alternativeManufacturerName: string,
) {
  const index = preset.value.alternativeManufacturerNames.indexOf(
    alternativeManufacturerName,
  )

  if (index !== -1) {
    preset.value.alternativeManufacturerNames.splice(index, 1)
  }
}

function handleDeviceAlternativeNames(hasAlternative: HasAlternativeNames) {
  deviceHasAlternativeNames.value = hasAlternative

  if (hasAlternative === HasAlternativeNames.No) {
    removeAllAlternativeDeviceNames()
    newAlternativeDeviceName.value = ''
    updateOpenAlexCount()
  } else {
    nextTick(() => alternativeDeviceNameInput.value?.focus())
  }
}

function handleManufacturerAlternativeNames(
  hasAlternative: HasAlternativeNames,
) {
  manufacturerHasAlternativeNames.value = hasAlternative

  if (hasAlternative === HasAlternativeNames.No) {
    removeAllAlternativeManufacturerNames()
    newAlternativeManufacturerName.value = ''
  } else {
    nextTick(() => alternativeManufacturerNameInput.value?.focus())
  }
}
</script>
