<template>
  <div class="w-full h-full">
    <teleport v-if="isInPlanStep" to="#toolbar">
      <Tabs v-model:tab="activeStep" :tabs="tabs" :style="2">
        <template #[lockPlanTabSlot]>
          <div
            v-if="
              review.entity.value.plan?.lockState === ReviewLockState.LOCKED
            "
            v-tooltip="'Unlock plan'"
            class="flex items-center gap-2 bg-red-600 text-white px-3 py-[10px] text-xs rounded ml-6 font-medium"
          >
            <LockClosedIcon class="w-4 h-auto" />
          </div>
          <div
            v-else-if="
              review.entity.value.plan?.lockState === ReviewLockState.UNLOCKED
            "
            v-tooltip="'Lock plan'"
            class="flex items-center gap-2 bg-green-600 text-white px-3 py-[10px] text-xs rounded ml-6 font-medium"
          >
            <LockOpenIcon class="w-4 h-auto" />
          </div>
        </template>
      </Tabs>

      <div
        v-if="warningMessage"
        class="bg-yellow-100 text-yellow-700 px-4 py-2 flex items-center gap-2 rounded-lg mx-6"
      >
        <InformationCircleIcon class="w-5 h-5" />
        {{ warningMessage }}
      </div>
    </teleport>
    <KeepAlive>
      <component :is="planStepComponentName" />
    </KeepAlive>
  </div>
</template>

<script lang="ts" setup>
import Strategy from './EligibilityCriteria.vue'
import Appraisal from './Appraisal/Appraisal.vue'
import PlanImportSources from './Search/Searches.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'
import Evaluators from './Evaluators/Evaluators.vue'
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import { HttpException } from '@core/exceptions/http.exception'
import { SnackbarState } from '@app/types'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import Objective from './Objective/Objective.vue'
import {
  InformationCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/vue/24/solid'
import { computed, onActivated, onDeactivated, ref } from 'vue'
import { PlanStep } from '../review.types'

const auth = injectStrict(AuthKey)

const isInPlanStep = ref(true)
onDeactivated(() => {
  isInPlanStep.value = false
})

onActivated(() => {
  isInPlanStep.value = true
})

const warningMessage = computed(() => {
  if (review.entity.value.plan?.lockState === ReviewLockState.LOCKED) {
    return 'The plan is locked, unlock it to make changes.'
  }
  if (review.isArchived.value) {
    return "The review is archived, you can't make changes."
  }
  return ''
})
const planStepComponentName = computed(() => {
  switch (activeStep.value) {
    case PlanStep.evaluators:
      return Evaluators
    case PlanStep.objective:
      return Objective
    case PlanStep.strategy:
      return Strategy
    case PlanStep.appraisal:
      return Appraisal
    case PlanStep.importSources:
      return PlanImportSources
    default:
      return Evaluators
  }
})

const activeStep = ref(PlanStep.evaluators)

const lockPlanTabSlot = `tab-${PlanStep.lock}`

const snackbar = useSnackbar()
const loading = useLoading()
const review = injectStrict(ReviewKey)
const tabs = [
  {
    number: PlanStep.evaluators,
    title: 'Evaluators',
  },
  {
    number: PlanStep.objective,
    title: 'Objective',
  },

  {
    number: PlanStep.strategy,
    title: 'Strategy',
  },
  {
    number: PlanStep.appraisal,
    title: 'Appraisal',
  },
  {
    number: PlanStep.importSources,
    title: 'Search',
  },
  ...(review.entity.value.plan?.lockState !== ReviewLockState.DISABLED
    ? [
        {
          number: PlanStep.lock,
          isDisabled:
            review.isArchived.value || auth.user.value?.role !== Role.ADMIN,
          handler: async () => {
            await togglePlanLock()
          },
        },
      ]
    : []),
]

async function togglePlanLock() {
  if (review.isArchived.value) return
  loading.start()
  try {
    if (review.entity.value.plan?.lockState === ReviewLockState.LOCKED) {
      if (review.entity.value.isLocked) {
        await unlockReview()
      }
      await unlockPlan()
    } else {
      await lockPlan()
    }
    await review.refresh()
  } finally {
    loading.stop()
  }
}

async function lockPlan() {
  try {
    await review.lockPlan(review.entity.value.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while locking review plan, please try again.',
      )
    }
    throw e
  }
}

async function unlockPlan() {
  try {
    await review.unlockPlan(review.entity.value.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while unlocking review plan, please try again.',
      )
    }
    throw e
  }
}

async function unlockReview() {
  try {
    await review.unlockReview(review.entity.value?.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while unlocking review, please try again.',
      )
    }
    throw e
  }
}
</script>
