<template>
  <Tabs
    v-model:tab="activeStepNumber"
    :tabs="steps"
    :style="3"
    @update:step="updateStep"
  >
    <template #tab-content-1>
      <AiAssistantSettings />
    </template>
    <template #tab-content-2>
      <UserSettings />
    </template>

    <template #tab-content-3>
      <ExtensionSettings />
    </template>
    <template #tab-content-4>
      <div class="container px-6 my-6 space-y-4 text-sm">
        version: {{ version }}
      </div>
    </template>
  </Tabs>
</template>
<script setup lang="ts">
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import AiAssistantSettings from './AiAssistantSettings.vue'
import ExtensionSettings from './ExtensionSettings.vue'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import UserSettings from './UserSettings/UserSettings.vue'
const version = import.meta.env.VITE_EVIDENCE_VERSION

const { user } = injectStrict(AuthKey)

const steps = [
  ...(user.value?.role === Role.ADMIN
    ? [
        {
          number: 1,
          title: 'AI Assistant ',
        },
      ]
    : []),
  {
    number: 2,
    title: 'User',
  },
  ...(user.value?.role !== Role.READ_ONLY
    ? [
        {
          number: 3,
          title: 'Extension ',
        },
      ]
    : []),
  {
    number: 4,
    title: 'About',
  },
]
const route = useRoute()
const router = useRouter()
const activeStepNumber = ref(steps[0].number)
const activeSubStepNumber = ref(1)
if (route.query.tab) {
  activeStepNumber.value = Number(route.query.tab)
}
if (route.query.subtab) {
  activeSubStepNumber.value = Number(route.query.subtab)
}

function updateStep(step: number) {
  router.push({ query: { tab: step, subtab: 1 } })
}
</script>
