<template>
  <div
    v-if="!shouldRender"
    v-intersection-observer="onIntersectionObserver"
    class="flex justify-between gap-2 px-2 py-1 items-center w-full h-[90px] col-span-full pl-8"
  >
    <div class="w-full gap-2 min-w-0 flex">
      <div class="w-full">
        <h1 class="text-md truncate text-white">Loading</h1>
        <p class="text-gray-500">please wait</p>
      </div>
    </div>
  </div>
  <template v-else>
    <ReviewItemDetails
      :expansion-panel="expansionPanel"
      :is-metadata-expanded="isMetadataExpanded"
      :review-item="study"
    />
    <template v-if="screeningStage">
      <Screening
        v-model="isHoveredOver"
        :expansion-panel="expansionPanel"
        :review-item="study"
        :class="{
          'bg-purple-50': study.state === StudyState.DUPLICATE,
        }"
      />
    </template>
    <StudyDesign
      v-if="props.studyDesignStage"
      :study="study"
      :expansion-panel="expansionPanel"
    />
    <StudyAppraisal :study="study" />
    <AttributeExtraction
      v-if="props.synthesisStage"
      v-model="isHoveredOver"
      :is-expanded="expansionPanel.isShown.value"
      :study="study"
    />
  </template>
</template>

<script lang="ts" setup>
import useExpansionPanel from '@app/composables/use-expansion-panel'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { ref } from 'vue'
import StudyAppraisal from './StudyAppraisal/StudyAppraisal.vue'
import AttributeExtraction from '@app/views/Review/Execute/Studies/AttributeExtraction/AttributeExtraction.vue'
import { vIntersectionObserver } from '@vueuse/components'
import StudyDesign from './StudyDesign/StudyDesign.vue'
import ReviewItemDetails from './ReviewItemDetails/ReviewItemDetails.vue'
import Screening from './Screening/Screening.vue'
import { StudyState } from '@app/types'

const isHoveredOver = ref(false)

const props = withDefaults(
  defineProps<{
    study: ReviewItem
    studyDesignStage?: boolean
    screeningStage?: boolean
    synthesisStage?: boolean
    studyDesignDrawer?: boolean
    isMetadataExpanded?: boolean
  }>(),
  {
    synthesisStage: true,
    isMetadataExpanded: false,
  },
)

const expansionPanel = useExpansionPanel()

const shouldRender = ref(false)

function onIntersectionObserver([
  { isIntersecting },
]: IntersectionObserverEntry[]) {
  if (!isIntersecting) return
  shouldRender.value = true
}
</script>

<style>
.duplication-parent::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  bottom: -2px;
  border-left: 6px solid;
  @apply border-violet-600;
  z-index: 1;
}

.duplication-parent::after {
  content: '';
  position: absolute;
  left: -2px;
  top: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  @apply bg-violet-600;
}

.duplicate::before {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: 0;
  border-left: 6px solid;
  @apply border-violet-600;
  z-index: 1;
}
.maybe-dupllicate::before {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: 0;
  width: 5px;
  background-image: linear-gradient(
    45deg,
    #7c3aed 33.33%,
    #ffffff 33.33%,
    #ffffff 50%,
    #7c3aed 50%,
    #7c3aed 83.33%,
    #ffffff 83.33%,
    #ffffff 100%
  );
  background-size: 21.21px 21.21px;
  z-index: 1;
}

.quarantined {
  background-image: linear-gradient(
    135deg,
    #f2e42719 25%,
    #00000019 25%,
    #00000019 50%,
    #f2e42719 50%,
    #f2e42719 75%,
    #00000019 75%,
    #00000019 100%
  );

  background-size: 113.14px 113.14px;
}

.maybe-dupllicate {
  background-image: linear-gradient(
    135deg,
    #f2e42719 25%,
    #00000019 25%,
    #00000019 50%,
    #f2e42719 50%,
    #f2e42719 75%,
    #00000019 75%,
    #00000019 100%
  );

  background-size: 113.14px 113.14px;
}
</style>
