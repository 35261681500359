<template>
  <div class="space-y-6">
    <div>
      <label class="mb-2 block">Condition</label>
      <div
        v-if="preset.problemName"
        class="flex items-center w-fit text-sm gap-2 px-3 py-2 rounded-full text-primary bg-gray-200 leading-none"
      >
        {{ preset.problemName }}
        <button @click="clearConditionName()">
          <XCircleIcon class="w-5 h-5" />
        </button>
      </div>
      <TextInput v-else class="w-96" @enter="setConditionName">
        <template #suffix>
          <div
            class="inline-flex items-center rounded border shadow-md border-gray-200 px-2.5 font-sans text-xs text-gray-400 py-1 gap-1"
          >
            <ArrowTurnDownLeftIcon class="w-3.5" />
          </div>
        </template>
      </TextInput>
    </div>
    <div v-if="medicalBackgroundConditionNames.length > 0">
      <div>Condition names</div>
      <div class="flex flex-wrap gap-2 items-center">
        <div
          v-for="name in medicalBackgroundConditionNames"
          :key="name"
          class="text-sm p-2 rounded-full text-blue-800 bg-blue-200 flex items-center gap-1"
        >
          {{ name }}
          <button @click="deleteMedicalBackgroundConditionName(name)">
            <XCircleIcon class="w-5" />
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="loadingMessage"
      class="py-2 px-1"
      v-html="loadingMessage + animatedDots"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { CreateReviewKey } from '../../../useCreateProjectReview'
import { ArrowTurnDownLeftIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { generateMessageWithDelay } from '@app/utils/generateTextWithDelay'
import { ref } from 'vue'

const {
  preset,
  medicalBackgroundConditionNames,
  deleteMedicalBackgroundConditionName,
  findMedicalBackgroundConditionNames,
  levelOfEvidence,
  timeFrame,
  isGatheringBackgroundInformation,
} = injectStrict(CreateReviewKey)

async function setConditionName(name: string) {
  isGatheringBackgroundInformation.value = true
  try {
    preset.value.problemName = name
    await Promise.all([
      appendMessage('Looking up other condition names'),
      findMedicalBackgroundConditionNames(),
    ]).then(() => {
      loadingMessage.value = ''
    })
  } finally {
    isGatheringBackgroundInformation.value = false
  }
}

function clearConditionName() {
  preset.value.problemName = ''
  levelOfEvidence.value = ''
  timeFrame.value = 0
  medicalBackgroundConditionNames.value = []
}

const loadingMessage = ref('')

async function appendMessage(
  message: string,
  delayInMs: number = 1000,
): Promise<void> {
  const generator = generateMessageWithDelay(message)
  for await (const word of generator) {
    loadingMessage.value += `${word} `
  }
  await new Promise<void>((resolve) => setTimeout(resolve, delayInMs))
}

const animatedDots = ref('')

setInterval(() => {
  animatedDots.value =
    animatedDots.value.length < 3 ? animatedDots.value + '.' : '.'
}, 200)
</script>
