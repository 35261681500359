import { generateSearchQuery } from '@app/utils/generateSearchQuery'
import { FieldSafetyNotice } from '@core/domain/models/fieldSafetyNotice.model'
import { Review } from '@core/domain/models/review.model'
import { ArticleMetadata } from '@core/domain/models/reviewItemMetadata/articleMetadata'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import { ReviewsRepository } from '@infrastructure/datasource/reviews.repository'
import { EuropePmcApiClient } from '@infrastructure/europePmcApi/europePmcApi.client'
import { FsnApiClient } from '@infrastructure/fsnApi/fsnApi.client'
import { OpenAlexApiClient } from '@infrastructure/openAlexApi/openAlexApi.client'
import { PmcApiClient } from '@infrastructure/pmcApi/pmcApi.client'
import { PubmedApiClient } from '@infrastructure/pubmedApi/pubmedApi.client'

export class AutoImportService {
  constructor(
    private readonly _reviewsRepository: ReviewsRepository,
    private readonly pmcApiClient: PmcApiClient,
    private readonly europePmcApiClient: EuropePmcApiClient,
    private readonly openAlexApiClient: OpenAlexApiClient,
    private readonly pubmedApiClient: PubmedApiClient,
    private readonly fsnApiClient: FsnApiClient,
  ) {}

  async *handleWithProgress({ reviewId }: { reviewId: number }) {
    try {
      const review = await this._reviewsRepository.findById(reviewId)
      if (!review) return
      if (
        review.plan?.preset !== ReviewPreset.DEVICE_SPECIFIC_SEARCH &&
        review.plan?.preset !== ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH
      )
        return

      const sourceApis = this.getSources(review.plan.preset)
      const importSourcesMap = this.createImportSourcesMap(review)

      for (const sourceApi of sourceApis) {
        const source = importSourcesMap.get(sourceApi.id)!
        yield `Searching in ${source.name} for ${generateSearchQuery(
          source,
          review,
        )}`
        await this.processSource(sourceApi, review, importSourcesMap)
      }
    } catch (error: any) {
      console.error(
        `Error handling ReviewCreatedEvent: ${error.message}`,
        error.stack,
      )
    }
  }
  async handle({ reviewId }: { reviewId: number }) {
    try {
      const review = await this._reviewsRepository.findById(reviewId)
      if (!review) return
      if (
        review.plan?.preset !== ReviewPreset.DEVICE_SPECIFIC_SEARCH &&
        review.plan?.preset !== ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH
      )
        return

      const sources = this.getSources(review.plan.preset)
      const importSourcesMap = this.createImportSourcesMap(review)

      const tasks = sources.map((source) =>
        this.processSource(source, review, importSourcesMap),
      )
      await Promise.all(tasks)
    } catch (error: any) {
      console.error(
        `Error handling ReviewCreatedEvent: ${error.message}`,
        error.stack,
      )
    }
  }

  private getSources(preset: ReviewPreset) {
    const sources = [
      {
        client: this.pubmedApiClient,
        id: BuiltInImportSourceId.PUBMED,
      },
      {
        client: this.openAlexApiClient,
        id: BuiltInImportSourceId.OPEN_ALEX,
      },
      {
        client: this.europePmcApiClient,
        id: BuiltInImportSourceId.EUROPE_PMC,
      },
      {
        client: this.pmcApiClient,
        id: BuiltInImportSourceId.PMC,
      },
      ...(preset === ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH
        ? [
            {
              client: this.fsnApiClient,
              id: BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
            },
          ]
        : []),
    ]

    return sources
  }

  private createImportSourcesMap(review: Review) {
    return new Map(
      review.plan?.importPlan?.importSources?.map((s) => [s.id, s]),
    )
  }

  private async processSource(
    source: any,
    review: Review,
    importSourcesMap: any,
  ) {
    try {
      const importSource = importSourcesMap.get(source.id)
      const query = generateSearchQuery(importSource, review)

      let articles: ArticleMetadata[] | FieldSafetyNotice[]
      let queryTranslation: string | undefined

      if (source.id === BuiltInImportSourceId.FIELD_SAFETY_NOTICES) {
        articles = await source.client.searchArticles(
          query,
          review.plan?.fsnCountries,
        )
      } else if (
        source.id === BuiltInImportSourceId.PUBMED ||
        source.id === BuiltInImportSourceId.PMC
      ) {
        const result = await source.client.searchArticles(query)
        articles = result.articles
        queryTranslation = result.queryTranslation
      } else {
        articles = await source.client.searchArticles(query)
      }

      await this._reviewsRepository.importSearch({
        reviewId: review.id,
        date: new Date().toISOString(),
        query,
        importSourceId: importSource.id,
        queryTranslation,
        items:
          importSource.type === ImportSourceType.FIELD_SAFETY_NOTICES
            ? articles?.map((article) => ({
                type: ReviewItemType.FieldSafetyNotice,
                ...article,
              }))
            : articles?.map((article) => ({
                type: ReviewItemType.Article,
                ...article,
              })),
      })
    } catch (error: any) {
      console.error(
        `Error processing source ${source.name}: ${error.message}`,
        error.stack,
      )
    }
  }
}
