<template>
  <div class="relative" :class="[isExpanded ? 'h-full' : 'h-32']">
    <div class="h-full">
      <editor-content :editor="editor" class="h-full bg-transparent" />
      <bubble-menu
        v-if="editor"
        :editor="editor"
        :tippy-options="{ duration: 100, theme: 'light-border' }"
      >
        <div class="bubble-menu">
          <button
            v-for="style in bubbleMenuStyles"
            :key="style.id"
            class="p-2 border border-transparent"
            :class="{
              'bg-slate-200 border-white': editor.isActive(style.id),
            }"
            @click="style.handler(editor)"
            @mousedown.prevent
          >
            <component :is="style.icon" class="w-5" />
          </button>
        </div>
      </bubble-menu>
      <floating-menu
        v-if="editor"
        :editor="editor"
        :tippy-options="{
          duration: 100,
          placement: 'top',
          theme: 'light-border',
        }"
      >
        <div class="floating-menu">
          <button
            v-for="style in floatingMenuStyles"
            :key="style.id"
            class="p-2 border border-transparent"
            :class="{
              'bg-slate-200 border-white': editor.isActive(style.id),
            }"
            @click="style.handler(editor)"
            @mousedown.prevent
          >
            <component :is="style.icon" class="w-5" />
          </button>
        </div>
      </floating-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import HardBreak from '@tiptap/extension-hard-break'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Dropcursor from '@tiptap/extension-dropcursor'
import ListKeymap from '@tiptap/extension-list-keymap'
import {
  EditorContent,
  useEditor,
  BubbleMenu,
  Editor,
  FloatingMenu,
} from '@tiptap/vue-3'

import Text from '@tiptap/extension-text'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import { ref, watch, watchEffect, onBeforeUnmount } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { ReviewKey } from '@app/views/Review/use-review'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import OrderedList from '@tiptap/extension-ordered-list'
import {
  BoldIcon,
  ItalicIcon,
  ListBulletIcon,
  NumberedListIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from '@heroicons/vue/24/outline'
import { ReviewItem } from '@core/domain/models/reviewItem.model'

const props = defineProps<{
  isExpanded: boolean
  isReviewReadonly: boolean
  placeholder?: string
  id: string
  study: ReviewItem
}>()
const html = defineModel<string>('html')
const text = defineModel<string>('text')
const { user, color } = injectStrict(AuthKey)

const { dataExtractionProvider } = injectStrict(ReviewKey)

const editorExtensions = [
  Document,
  Paragraph,
  Strike,
  OrderedList.configure({
    HTMLAttributes: {
      class: 'list-decimal pl-4',
    },
  }),

  Text,
  Italic,
  Underline,
  Bold,
  HardBreak,
  BulletList.configure({
    HTMLAttributes: {
      class: 'list-disc pl-4',
    },
  }),
  ListItem,
  Dropcursor,
  ListKeymap,
  Placeholder.configure({
    placeholder:
      'Enter the information extracted from the record. Use a dash "-" if the record does not contain information.',
  }),
  Collaboration.configure({
    document: dataExtractionProvider.document,
    field: 'study::' + props.study.id + '|attribute::' + props.id,
  }),
  CollaborationCursor.configure({
    provider: dataExtractionProvider,
    user: {
      name: user.value?.firstName + ' ' + user.value?.lastName,
      color: color.value,
    },
  }),
]
const isFocused = ref(false)

const editor = useEditor({
  editable: !props.isReviewReadonly,
  extensions: editorExtensions,
  injectCSS: false,
  onBlur: ({ editor }) => {
    isFocused.value = false
    editor.commands.setTextSelection(0)
  },
  onFocus: () => {
    isFocused.value = true
  },
  onUpdate({ editor }) {
    html.value = editor.getHTML()
    text.value = editor.getText()
  },
})

const boldStyle = {
  id: 'bold',
  handler: (editor: Editor) => editor.chain().focus().toggleBold().run(),
  title: 'Bold',
  icon: BoldIcon,
}

const italicStyle = {
  id: 'italic',
  handler: (editor: Editor) => editor.chain().focus().toggleItalic().run(),
  title: 'Italic',
  icon: ItalicIcon,
}

const bulletListStyle = {
  id: 'bulletList',
  handler: (editor: Editor) => editor.chain().focus().toggleBulletList().run(),
  title: 'List',
  icon: ListBulletIcon,
}

const underlineStyle = {
  id: 'underline',
  handler: (editor: Editor) => editor.chain().focus().toggleUnderline().run(),
  title: 'Underline',
  icon: UnderlineIcon,
}

const strikeStyle = {
  id: 'strike',
  handler: (editor: Editor) => editor.chain().focus().toggleStrike().run(),
  title: 'Strike',
  icon: StrikethroughIcon,
}

const orderedListStyle = {
  id: 'orderedList',
  handler: (editor: Editor) => editor.chain().focus().toggleOrderedList().run(),
  title: 'Ordered list',
  icon: NumberedListIcon,
}

const bubbleMenuStyles = [
  boldStyle,
  italicStyle,
  underlineStyle,
  strikeStyle,
  bulletListStyle,
  orderedListStyle,
]

const floatingMenuStyles = [bulletListStyle, orderedListStyle]

watch(
  () => html.value,
  (value) => {
    const content = editor.value?.getHTML()
    if (content !== value) {
      setEditorContent(value ?? '')
    }
  },
)
watch(
  () => props.isReviewReadonly,
  (isReadonly) => {
    if (editor.value) {
      editor.value?.setEditable(!isReadonly)
    }
  },
)

function setEditorContent(value: string) {
  editor.value?.commands.setContent(value ?? '')
}

watchEffect(() => {
  if (editor.value)
    editor.value.setOptions({
      editorProps: {
        attributes: {
          class: [
            'p-2 pr-8  w-full min-h-[8rem]',
            isFocused.value
              ? ' overflow-auto absolute top-0 left-0 z-10 bg-white'
              : 'h-full overflow-hidden',
            isFocused.value && props.isExpanded && 'h-full',
            isFocused.value && !props.isExpanded && 'max-h-80',
          ].join(' '),
        },
      },
    })
})

onBeforeUnmount(() => {
  editor.value?.destroy()
  dataExtractionProvider.destroy()
})

defineExpose({
  setEditorContent,
})
</script>
<style>
.tiptap p.is-editor-empty:first-child::before {
  color: var(--color-slate-400);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap .collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.tiptap .collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: +1.4em;
  user-select: none;
  white-space: nowrap;
}
</style>
