<template>
  <div class="p-4 space-y-4">
    <div>
      <div>
        <label for="systemPrompt">System prompt</label>
        <textarea
          id="systemPrompt"
          v-model="systemPrompt"
          class="w-full border rounded-md p-2 pr-8 h-40"
          placeholder="Enter a system prompt"
        />
      </div>
      <div>
        <label for="summarySystemPrompt">Summary system prompt</label>
        <textarea
          id="summarySystemPrompt"
          v-model="summarySystemPrompt"
          class="w-full border rounded-md p-2 pr-8 h-40"
          placeholder="Enter a summary system prompt"
        />
      </div>
      <div class="flex items-center gap-2">
        <div
          v-tooltip="
            `What sampling temperature to use, between 0 and 2.
             Higher values like 0.8 will make the output more random, while 
             lower values like 0.2 will make it more focused and deterministic.`
          "
          class="flex items-center gap-1 w-40"
        >
          temperature <InformationCircleIcon class="w-4" />
        </div>
        <div class="w-8">
          {{ modelTemperature }}
        </div>
        <input
          v-model.number="modelTemperature"
          type="range"
          min="0.2"
          max="1.0"
          step="0.1"
        />
      </div>

      <div class="flex items-center gap-2">
        <div
          v-tooltip="
            `Number between -2.0 and 2.0. Positive values penalize new 
            tokens based on whether they appear in the text so far, 
            increasing the model's likelihood to talk about new topics.`
          "
          class="flex items-center gap-1 w-40"
        >
          Presence penalty
          <InformationCircleIcon class="w-4" />
        </div>
        <div class="w-8">
          {{ modelPresencePenalty }}
        </div>
        <input
          v-model.number="modelPresencePenalty"
          type="range"
          min="-2"
          max="2"
          step="0.1"
        />
      </div>
    </div>

    <div class="border-t pt-6">
      <button class="bg-primary text-white px-2 py-1" @click="generate">
        Generate
      </button>
      <div v-for="line in extraction" :key="line.id">
        <div class="font-bold">{{ line.question }}</div>
        <ExpansionPanel
          v-for="(answer, index) in line.answers"
          :key="'answer-' + index"
          :model-value="false"
        >
          <template #activator="{ toggle, isShown }">
            <p>
              {{ answer.answer }}
              <button class="text-blue-600 underline" @click="toggle">
                {{ isShown ? 'hide details' : 'show details' }}
              </button>
            </p>
          </template>
          <template #default>
            <div>{{ answer.originalText }}</div>
          </template>
        </ExpansionPanel>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import useLoading from '@app/composables/use-loading'

import PdfjsWorker from 'pdfjs-dist/build/pdf.worker.min.mjs?worker&url'
import * as pdfjs from 'pdfjs-dist'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import { useDataExtractionAssistant } from './assistant'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

pdfjs.GlobalWorkerOptions.workerSrc = PdfjsWorker

const props = defineProps<{
  study: ReviewItem
}>()

const review = injectStrict(ReviewKey)
const loading = useLoading()
const {
  extraction,
  generate: generateExtractionAnswers,
  systemPrompt,
  modelTemperature,
  modelPresencePenalty,
  summarySystemPrompt,
} = useDataExtractionAssistant(props.study.id)

const emit =
  defineEmits<
    (
      e: 'completed',
      v: { question: string; answer: string; id: string }[],
    ) => void
  >()
async function generate() {
  loading.start()
  try {
    if (!review.entity.value.plan?.synthesisPlan.attributesStructure) {
      throw new Error('No attributes structure found')
    }
    const blob = await review.getStudyPdfFile(props.study.id)
    const qaPairs = await generateExtractionAnswers(
      blob,
      review.entity.value.plan?.synthesisPlan.attributesStructure,
    )
    if (qaPairs) {
      emit('completed', qaPairs)
    }
  } finally {
    loading.stop()
  }
}
</script>
