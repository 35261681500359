<template>
  <div>
    <TabStyle1
      v-if="style === 1"
      :active-tab-number="activeTabNumber"
      :tabs="tabs"
      @tab:clicked="setActiveTab"
    >
      <template #default="{ tab }">
        <slot :name="`tab-${tab.number}`" :tab="tab" />
      </template>
    </TabStyle1>

    <TabStyle2
      v-else-if="style === 2"
      :active-tab-number="activeTabNumber"
      :tabs="tabs"
      @tab:clicked="setActiveTab"
    >
      <template #default="{ tab }">
        <slot :name="`tab-${tab.number}`" :tab="tab" />
      </template>
    </TabStyle2>

    <TabStyle3
      v-if="style === 3"
      :active-tab-number="activeTabNumber"
      :tabs="tabs"
      @tab:clicked="setActiveTab"
    >
      <template #default="{ tab }">
        <slot :name="`tab-${tab.number}`" :tab="tab" />
      </template>
    </TabStyle3>

    <template v-for="tab in tabs">
      <slot
        v-if="activeTabNumber === tab.number"
        :name="`tab-content-${tab.number}`"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import TabStyle1 from './TabStyle1.vue'
import TabStyle2 from './TabStyle2.vue'
import TabStyle3 from './TabStyle3.vue'
type Tab = {
  number: number
  title?: string
  subtitle?: string
  isDisabled?: boolean
  handler?: () => void | Promise<void>
}

withDefaults(
  defineProps<{
    tabs: Tab[]

    style?: 1 | 2 | 3
  }>(),
  {
    style: 1,
  },
)

const activeTabNumber = defineModel<number>('tab', {
  default: 1,
})

function setActiveTab(tab: Tab) {
  if (tab.handler) {
    tab.handler()
    return
  }
  if (tab.number !== activeTabNumber.value) activeTabNumber.value = tab.number
}
</script>
