<template>
  <BulkExtraction
    v-if="isCuttingEdge"
    :study="study"
    @completed="setAttributes"
  />
  <div
    v-for="attributeStructure in review.currentAttributesStructure.value"
    :key="attributeStructure.id"
    class="border border-[#D9E4EA]"
    :class="{
      'bg-azureish-white/25': model,
    }"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
  >
    <AttributeVue
      v-if="isIncluded"
      :ref="
        (el) =>
          attributeEditors.push({
            id: attributeStructure.id,
            component: el,
          })
      "
      :attribute-structure="attributeStructure"
      :study="study"
      class=""
      :is-expanded="isExpanded"
      :class="{
        'bg-azureish-white/25': model,
      }"
      :is-review-read-only="review.isLocked.value"
    />
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import AttributeVue from './Attribute.vue'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { ReviewKey } from '../../../use-review'
import { computed, ref } from 'vue'
import { INCLUDED } from '@core/domain/types/included-screening-criterion.type'
import BulkExtraction from './BulkExtraction.vue'
import { DevToolboxKey } from '@app/injectionKeys'

const { isCuttingEdge } = injectStrict(DevToolboxKey)

const props = defineProps<{
  study: ReviewItem
  isExpanded: boolean
}>()
const model = defineModel<boolean>()

const attributeEditors = ref<any>([])

const review = injectStrict(ReviewKey)

const isIncluded = computed(() => {
  return props.study.fullTextScreening === INCLUDED
})

function onMouseOver() {
  model.value = true
}
function onMouseOut() {
  model.value = false
}

function setAttributes(
  attributes: { question: string; answer: string; id: string }[],
) {
  for (const attribute of attributes) {
    const editor = attributeEditors.value.find(
      (editor: any) => editor.id === attribute.id,
    )
    if (editor) {
      editor.component.setEditorContent(attribute.answer)
    }
  }
}
</script>
