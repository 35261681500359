<template>
  <Modal @hide="reviewCreate.reset()">
    <template #activator="{ show }">
      <button
        :class="props.class"
        class="bg-white dark:bg-[#275374] dark:border-white inline-flex items-center gap-1 py-2.5 px-3.5 border text-sm border-slate-300 shadow-sm rounded-md font-normal hover:brightness-110"
        @click.stop="show"
      >
        <MagnifyingGlassPlusIcon class="w-4 h-4" />
        <span class="text-slate-700 text-sm font-medium">Add review</span>
      </button>
    </template>
    <template #content="{ hide }">
      <div class="w-[980px] create-review-modal flex flex-col">
        <div
          class="flex p-6 justify-between items-center border-b border-slate-300"
        >
          <p
            class="text-slate-700 text-lg font-semibold capitalize text-center flex gap-2 items-center"
          >
            <WizardIcon class="h-6" />
            Review wizard
          </p>
          <button @click="hide">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
        <div class="flex-1 overflow-auto p-6">
          <div
            v-if="isLoading && loadingMessage"
            class="p-2"
            v-html="loadingMessage + animatedDots"
          />
          <KeepAlive v-else>
            <component
              :is="currentStepComponent"
              @create-review="createReview(hide)"
            />
          </KeepAlive>
        </div>
      </div>
      <div class="flex justify-between gap-6 p-6">
        <button
          class="w-full bg-white text-primary border border-primary rounded-md text-base tracking-tighter px-4 py-2 font-medium"
          :disabled="
            reviewCreate.currentStep.value ===
              CreateReviewStep.PresetSelection || isLoading
          "
          :class="{
            'opacity-50 cursor-not-allowed':
              reviewCreate.currentStep.value ===
              CreateReviewStep.PresetSelection,
            'hover:brightness-125':
              reviewCreate.currentStep.value !==
              CreateReviewStep.PresetSelection,
          }"
          @click="reviewCreate.goToPreviousStep"
        >
          Previous
        </button>
        <button
          v-if="
            [
              CreateReviewStep.Summary,
              CreateReviewStep.PmsDeviceLevelMinimal,
              CreateReviewStep.PmsSimilarDeviceLevelMinimal,
              CreateReviewStep.MedicalBackgroundConfiguration,
            ].includes(reviewCreate.currentStep.value)
          "
          class="w-full bg-primary text-white rounded-md text-base tracking-tighter px-4 py-2 font-medium"
          :disabled="!reviewCreate.isCurrentStepValid.value || isLoading"
          :class="{
            'opacity-50 cursor-not-allowed':
              !reviewCreate.isCurrentStepValid.value || isLoading,
            'hover:brightness-125':
              reviewCreate.isCurrentStepValid.value && !isLoading,
          }"
          @click="createReview(hide)"
        >
          Confirm
        </button>
        <button
          v-else
          class="w-full bg-primary text-white rounded-md text-base tracking-tighter px-4 py-2 font-medium"
          :disabled="!reviewCreate.isCurrentStepValid.value"
          :class="{
            'opacity-50 cursor-not-allowed':
              !reviewCreate.isCurrentStepValid.value,
            'hover:brightness-125': reviewCreate.isCurrentStepValid.value,
          }"
          @click="reviewCreate.goToNextStep"
        >
          Next
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import { provide, computed, ref } from 'vue'
import MagnifyingGlassPlusIcon from '@app/components/Icons/MagnifyingGlassPlusIcon.vue'
import useSnackbar from '@app/composables/use-snackbar'
import { HttpException } from '@core/exceptions/http.exception'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import useCreateProjectReview, {
  CreateReviewKey,
  CreateReviewStep,
} from './useCreateProjectReview'
import useProjects from '../../use-projects'
import PresetSelection from './ReviewPreset/PresetSelection.vue'
import DeviceSpecificSearchLevel from './ReviewPreset/Presets/DeviceSpecificSearchPreset/DeviceSpecificSearchLevel.vue'
import Summary from './Summary/Summary.vue'
import GenericDeviceGroupPreset from './ReviewPreset/Presets/GenericDeviceGroupPreset.vue'
import MedicalAlternativePreset from './ReviewPreset/Presets/MedicalAlternativePreset.vue'
import DeviceSpecificSearchMinimalPreset from './ReviewPreset/Presets/DeviceSpecificSearchPreset/DeviceSpecificSearchMinimalPreset.vue'
import WizardIcon from '@app/components/Icons/WizardIcon.vue'
import { useRouter } from 'vue-router'
import { generateMessageWithDelay } from '@app/utils/generateTextWithDelay'
import MedicalBackgroundConfiguration from './ReviewPreset/Presets/MedicalBackgroundPreset/MedicalBackgroundConfiguration.vue'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import { useMedicalBackgroundPresetAssistant } from './ReviewPreset/Presets/MedicalBackgroundPreset/assistant'

const medicalBackgroundAssistant = useMedicalBackgroundPresetAssistant()
const props = defineProps<{
  projectId: Id
  class?: string
}>()

const isLoading = ref(false)
const loadingMessage = ref('')
const stepsComponents = {
  [CreateReviewStep.PresetSelection]: PresetSelection,
  [CreateReviewStep.PmsDeviceLevelSelection]: DeviceSpecificSearchLevel,
  [CreateReviewStep.PmsDeviceLevelMinimal]: DeviceSpecificSearchMinimalPreset,

  [CreateReviewStep.PmsSimilarDeviceLevelSelection]: DeviceSpecificSearchLevel,
  [CreateReviewStep.PmsSimilarDeviceLevelMinimal]:
    DeviceSpecificSearchMinimalPreset,
  [CreateReviewStep.genericDeviceGroupConfiguration]: GenericDeviceGroupPreset,
  [CreateReviewStep.MedicalAlternativeConfiguration]: MedicalAlternativePreset,
  [CreateReviewStep.MedicalBackgroundConfiguration]:
    MedicalBackgroundConfiguration,
  [CreateReviewStep.Summary]: Summary,
}
const reviewCreate = useCreateProjectReview(props.projectId)

const currentStepComponent = computed(() => {
  return stepsComponents[reviewCreate.currentStep.value]
})
const projects = useProjects()
provide(CreateReviewKey, reviewCreate)
const snackbar = useSnackbar()

const router = useRouter()

async function createReview(cb: () => void) {
  if (!reviewCreate.isCurrentStepValid.value) return
  reviewCreate.generateReviewName()
  isLoading.value = true

  try {
    await appendMessage('Creating review ' + reviewCreate.reviewName.value)
    if (reviewCreate.preset.value.preset === ReviewPreset.MEDICAL_BACKGROUND) {
      reviewCreate.preset.value.purpose = 'treatment or diagnosis'
      reviewCreate.preset.value.problem =
        await medicalBackgroundAssistant.classifyConditionCategory(
          reviewCreate.preset.value.problemName,
        )
    }
    const [, reviewId] = await Promise.all([
      appendMessage('<br/>Generating plan'),
      reviewCreate.createReview(),
    ])
    for await (const message of reviewCreate.autoImportItems(reviewId)) {
      await appendMessage('<br/>' + message)
    }

    await appendMessage(
      '<br/><span class="text-green-600">Review created successfully</span>',
    )

    router.push(`/reviews/${reviewId}`)
    reviewCreate.reset()
    projects.refresh()
    hideAll()
    cb()
    snackbar.show(SnackbarState.SUCCESS, 'Review created successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    isLoading.value = false
  }
}

async function appendMessage(
  message: string,
  delayInMs: number = 1000,
): Promise<void> {
  const generator = generateMessageWithDelay(message)
  for await (const word of generator) {
    loadingMessage.value += `${word} `
  }
  await new Promise<void>((resolve) => setTimeout(resolve, delayInMs))
}

const animatedDots = ref('')

setInterval(() => {
  animatedDots.value =
    animatedDots.value.length < 3 ? animatedDots.value + '.' : '.'
}, 200)
</script>
<style>
.create-review-modal {
  height: min(800px, calc(100vh - 300px));
}
</style>
